import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import "ant-design-vue/es/notification/style/css";
import _notification from "ant-design-vue/es/notification";
import { UserOutlined, LockOutlined, ExclamationCircleOutlined, Button } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, reactive, ref, h } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useStore } from 'vuex';
import { selectUserForLogin, insertRegister } from '@/api/login';
import { admin, projectPersonnel, companyAdmin, companypersonnel, projectOver } from '@/utils/perJurisdiction';
import getFieldRules from '@/utils/fieldRules';
const columns = [{
  title: '项目名称',
  dataIndex: 'projectName'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const funColumns = [{
  title: '项目名称1',
  dataIndex: 'name1',
  width: 100,
  customRender: ({
    text,
    index
  }) => {
    const obj = {
      children: text,
      props: {
        rowSpan: 0
      }
    };

    if (index === 0) {
      obj.props.rowSpan = 5;
    }

    if (index === 5) {
      obj.props.rowSpan = 8;
    }

    if (index === 13) {
      obj.props.rowSpan = 6;
    }

    if (index === 19) {
      obj.props.rowSpan = 2;
    }

    if (index === 21) {
      obj.props.rowSpan = 3;
    }

    if (index === 24) {
      obj.props.rowSpan = 5;
    }

    if (index === 29) {
      obj.props.rowSpan = 6;
    }

    if (index === 35) {
      obj.props.rowSpan = 8;
    }

    if (index === 43) {
      obj.props.rowSpan = 3;
    }

    if (index === 46) {
      obj.props.rowSpan = 1;
    }

    if (index === 47) {
      obj.props.rowSpan = 2;
    }

    if (index === 49) {
      obj.props.rowSpan = 2;
    }

    return obj;
  }
}, {
  title: '项目名称2',
  dataIndex: 'name2',
  width: 120
}, {
  title: '项目名称3',
  dataIndex: 'name3'
}];
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    ExclamationCircleOutlined
  },

  setup(props, context) {
    const formRef = ref();
    const checked = ref(false);
    const router = useRouter();
    const route = useRoute();
    const formState = reactive({
      userName: '',
      userPassword: '',
      source: 1
    });
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 24
      }
    };

    let validatePass = async (rule, value) => {
      if (value === '') {
        return Promise.reject('用户名不能为空！');
      } else {
        // if (formState.checkPass !== '') {
        //   formRef.value.validateField('checkPass');
        // }
        return Promise.resolve();
      }
    };

    let validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('密码不能为空！');
      } else {
        return Promise.resolve();
      }
    };

    let validatePass3 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('密码不能为空！');
      } else {
        if (registerData.userPassword !== value) {
          return Promise.reject('两次输入的密码不一致！');
        }

        return Promise.resolve();
      }
    };

    const {
      checkName,
      email,
      checkPhone,
      userName,
      userPassword
    } = getFieldRules();
    const rules = {
      userName: [{
        validator: validatePass,
        trigger: 'change'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      userPassword: [{
        validator: validatePass2,
        trigger: 'change'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };
    const rules1 = {
      userName: [{
        required: true,
        validator: userName,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      userPassword: [{
        required: true,
        validator: userPassword,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      secondPassword: [{
        required: true,
        validator: validatePass3,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      companyName: [{
        required: true,
        message: '公司名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      belongProjectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      applicantName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      email: [{
        required: true,
        validator: email,
        trigger: 'blur'
      }],
      mobilePhone: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }]
    };
    const store = useStore(); //点击登录

    const onSubmit = () => {
      loadingObj.loading = true;
      formRef.value.validate().then(() => {
        // console.log('values', formState, toRaw(formState));
        const passwordRsa = window.$encryptByPublicKey(formState.userPassword);
        const loginData = {
          userName: formState.userName,
          userPassword: passwordRsa,
          source: formState.source // userPassword: CryptoJS.AES.encrypt(
          //         passwordRsa + "",
          //         "secretkey123"
          //       ).toString()

        };
        loginFn(loginData, 'loading');
      }).catch(error => {
        loadingObj.loading = false;
        console.log('error', error);
      });
    }; //提示框


    const openNotification = msg => {
      _notification.open({
        message: msg,
        duration: 0,
        icon: h(ExclamationCircleOutlined, {
          style: 'color: red'
        })
      });
    }; //记住密码


    const handleChecked = val => {
      if (checked.value) {
        Cookies.set("rememberPassword", checked.value, {
          expires: 356 // path: "/",

        });
      } else {
        Cookies.remove("rememberPassword");
      }
    };

    const getInfo = () => {
      checked.value = Cookies.get("rememberPassword") ? JSON.parse(Cookies.get("rememberPassword")) : false;

      if (checked.value) {
        formState.userName = Cookies.get("userName") ? Cookies.get("userName") : '';
        const password = Cookies.get("userPassword") ? Cookies.get("userPassword") : '';
        let bytes = CryptoJS.AES.decrypt(password, "secretkey123");
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        formState.userPassword = originalText;
      }
    };

    const projectVisible = ref(false);
    const projectData = ref([]);
    const loadingObj = reactive({
      loading: false,
      tabBtnLoading: false
    }); //点击选择项目里的登录

    const toLogin = async val => {
      loadingObj.tabBtnLoading = true;
      let twiceLoginData = {
        userName: val.userName,
        userPassword: val.userPassword,
        uid: val.uid,
        source: formState.source
      };
      loginFn(twiceLoginData, 'tabBtnLoading');
    }; //登录接口


    const loginFn = async (data, loadingVal) => {
      try {
        let res = await selectUserForLogin(data);

        if (res.code === 200) {
          _message.success(res.message);

          Cookies.set("token", res.data.token, {
            expires: 356,
            path: "/"
          });
          Cookies.set("userName", res.data.userName, {
            expires: 356,
            path: "/"
          });
          Cookies.set("belongProject", res.data.belongProject, {
            expires: 356,
            path: "/"
          });
          Cookies.set("isCompanyAdmin", res.data.isCompanyAdmin, {
            expires: 356,
            path: "/"
          });
          Cookies.set("projectState", res.data.projectState, {
            expires: 356,
            path: "/"
          });
          Cookies.set("isOnjob", res.data.isOnjob ? res.data.isOnjob : null, {
            expires: 356,
            path: "/"
          });
          Cookies.set("isFirstImport", res.data.isFirstImport, {
            expires: 356,
            path: "/"
          });
          Cookies.set("idCard", res.data.idCard ? res.data.idCard : null, {
            expires: 356,
            path: "/"
          });
          Cookies.set("uid", res.data.uid, {
            expires: 356,
            path: "/"
          });
          Cookies.set("nickName", res.data.nickName, {
            expires: 356,
            path: "/"
          });
          Cookies.set("menuGenre", res.data.menuGenre, {
            expires: 356,
            path: "/"
          });
          Cookies.set("workNo", res.data.workNo, {
            expires: 356,
            path: "/"
          });
          Cookies.set("companyName", res.data.companyName, {
            expires: 356,
            path: "/"
          });
          store.dispatch('setCompanyAdminStatus', res.data.isCompanyAdmin);
          store.dispatch('setProjectState', res.data.projectState); //帐号为项目管理员，且项目没有完结

          if (res.data.isCompanyAdmin == 0 && res.data.projectState != 0) {
            Cookies.set("btnPer", admin, {
              expires: 356,
              path: "/"
            }); //帐号为项目人员，且项目没有完结
          } else if (res.data.isCompanyAdmin == 3 && res.data.projectState != 0) {
            Cookies.set("btnPer", projectPersonnel, {
              expires: 356,
              path: "/"
            }); //帐号为项目人员，且项目没有完结
          } else if (res.data.projectState == 0) {
            Cookies.set("btnPer", projectOver, {
              expires: 356,
              path: "/"
            }); //帐号为公司人员
          } else if (res.data.projectState != 0 && res.data.belongProject == 10000) {
            Cookies.set("btnPer", companyAdmin, {
              expires: 356,
              path: "/"
            });
          }

          if (checked.value) {
            let cipherPsw = CryptoJS.AES.encrypt(formState.userPassword + "", "secretkey123").toString();
            Cookies.set("userPassword", cipherPsw, {
              expires: 356,
              path: "/"
            });
          } else {
            Cookies.remove("userPassword");
          }

          if (!res.data.isFirstLogin) {
            openNotification('请尽快修改密码');
          }

          if (res.data.needUpdatePwd) {
            openNotification('密码已过期，请尽快修改密码');
          }

          if (res.data.usageRate >= 90 && res.data.isCompanyAdmin == 0) {
            openNotification('存储空间将满，请及时扩充。');
          }

          router.push('/');
          loadingObj[loadingVal] = false;
        } else if (res.code === 201) {
          //201该账号下存在多个项目，可选择项目登录，项目选择框显示
          projectVisible.value = true;
          projectData.value = [];
          res.data.forEach(item => {
            let obj = {
              projectName: item.projectName,
              belongProject: item.belongProject,
              uid: item.uid,
              userName: data.userName,
              userPassword: data.userPassword
            };
            projectData.value.push(obj);
          });
          loadingObj[loadingVal] = false;
        } else {
          loadingObj[loadingVal] = false;

          _message.error(res.message);
        }

        loadingObj[loadingVal] = false;
      } catch (error) {
        loadingObj[loadingVal] = false;
      }
    }; //注册


    const registerData = reactive({
      userName: "",
      userPassword: "",
      secondPassword: "",
      menuGenre: 1,
      companyName: "",
      belongProjectName: "",
      applicantName: "",
      email: "",
      mobilePhone: "",
      accountType: 0,
      agree: null
    });
    const registerRef = ref();
    const registerVisible = ref(false);
    const registerLoading = ref(false);

    const handleCancel = () => {
      registerVisible.value = false;
      registerRef.value.resetFields();
    }; //注册提交


    const handleOk = () => {
      registerRef.value.validate().then(async () => {
        if (!registerData.agree) {
          _message.error('请先同意康能企服云的服务条款和隐私政策！');

          return;
        }

        const obj = {};
        Object.assign(obj, registerData);
        obj.userPassword = window.$encryptByPublicKey(obj.userPassword);
        obj.secondPassword = window.$encryptByPublicKey(obj.secondPassword);
        registerLoading.value = true;
        let res = await insertRegister(obj);

        if (res.code === 200) {
          _message.success(res.message);

          registerRef.value.resetFields();
          registerVisible.value = false;
        } else {
          _message.error(res.message);

          registerLoading.value = false;
        }

        registerLoading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const model1 = ref(false);
    const model2 = ref(false);
    const funData = ref([{
      name1: '个人信息',
      name2: '我的考勤',
      name3: '展示员工个人考勤信息'
    }, {
      name1: '个人信息',
      name2: '我的排班',
      name3: '展示员工个人排班信息'
    }, {
      name1: '个人信息',
      name2: '我的假期',
      name3: '展示员工个人假期信息，展示员工历史请假记录'
    }, {
      name1: '个人信息',
      name2: '我的绩效',
      name3: '员工可查看考核规则，填写考核内容/权重/得分，发起个人绩效考核'
    }, {
      name1: '个人信息',
      name2: '我的工资单',
      name3: '展示工资单管理中发布给员工个人的每月工资单信息'
    }, {
      name1: '人事管理',
      name2: '人员信息管理',
      name3: '对人员做新增/删除/修改/查询，可管理岗位/权限/薪资/雇佣期/黑名单'
    }, {
      name1: '人事管理',
      name2: '组织架构信息',
      name3: '展示人员组织架构信息和相关个人信息'
    }, {
      name1: '人事管理',
      name2: '组织管理',
      name3: '增/删/改/查部门信息、岗位信息、职级信息、工作地点'
    }, {
      name1: '人事管理',
      name2: '黑名单管理',
      name3: '对发生严重违反企业管理制度、造成恶劣影响的人员，录入黑名单'
    }, {
      name1: '人事管理',
      name2: '白名单管理',
      name3: '可开启白名单管理模式（仅已购买保险人员许可进入）'
    }, {
      name1: '人事管理',
      name2: '假期管理',
      name3: '记录员工的年假、企业带薪假、调休假、事假、病假等假期剩余，可设置假期更新规则，并联动请假审批更新数据，生成历史请假记录'
    }, {
      name1: '人事管理',
      name2: '工资单管理',
      name3: '设置薪资应发应扣项，创建月工资单，向员工发布/撤回'
    }, {
      name1: '人事管理',
      name2: '绩效管理',
      name3: '配置绩效考核规则后，可开放/关闭考核，对员工发起的绩效考核审批和修改'
    }, {
      name1: '考勤管理',
      name2: '当日考勤',
      name3: '展示当日的所有打卡信息'
    }, {
      name1: '考勤管理',
      name2: '考勤报表',
      name3: '对考勤数据自动统计，形成报表，包括日/月/年汇总考勤表，个人月/年考勤表'
    }, {
      name1: '考勤管理',
      name2: '考勤原始数据表',
      name3: '展示所有人员所有时间的打卡信息，可手动添加/批量添加考勤数据，并联动更新考勤报表'
    }, {
      name1: '考勤管理',
      name2: '通道设置',
      name3: '绑定考勤面板机，并设置单/双通道考勤'
    }, {
      name1: '考勤管理',
      name2: '工作时间设置',
      name3: '设置工作时间，以及迟到/早退/加班规则'
    }, {
      name1: '考勤管理',
      name2: '排班管理',
      name3: '设置班次/考勤组/排班周期，可按周期排班/按班次排班，查看日累计班次'
    }, {
      name1: '考核管理',
      name2: '考核报表',
      name3: '可关联考勤表自动生成考核表，包括日/月/年汇总考核表，个人月/年考核表'
    }, {
      name1: '考核管理',
      name2: '工资表',
      name3: '关联考勤考核表自动生成工资表，包括月总工资表，个人月工资表'
    }, {
      name1: '资料管理',
      name2: '施工日志',
      name3: '上传施工日志/现场照片，自动生成日志清单，按标签分类'
    }, {
      name1: '资料管理',
      name2: '安全日志',
      name3: '上传安全日志/现场照片，自动生成日志清单，按标签分类'
    }, {
      name1: '资料管理',
      name2: '其他资料',
      name3: '其它无时限要求资料上传，包括图纸/技术资料/事故报告/产品资料等'
    }, {
      name1: '材料管理',
      name2: '材料清单',
      name3: '针对进场、出库、退场材料统计生成材料清单'
    }, {
      name1: '材料管理',
      name2: '进场管理',
      name3: '按合同发起原材料进场审批流程'
    }, {
      name1: '材料管理',
      name2: '入库管理',
      name3: '仓库管理按照进场申请信息监督检查材料入库，生成材料清单'
    }, {
      name1: '材料管理',
      name2: '出库管理',
      name3: '在线发起材料使用申请，录入需使用材料名称/数量/时间/责任人/材料用途等'
    }, {
      name1: '材料管理',
      name2: '退场管理',
      name3: '针对使用中发现的不合格原材料，或项目完成后剩余材料，可发起退场申请'
    }, {
      name1: '审批管理',
      name2: '合同审批',
      name3: '输入合同名称及主要信息，合同责任人等，上传合同发起审批流程，生成合同列表'
    }, {
      name1: '审批管理',
      name2: '变更审批',
      name3: '选择需变更的合同并输入变更信息等，上传变更单发起审批流程，生成合同变更列表'
    }, {
      name1: '审批管理',
      name2: '付款审批',
      name3: '选择合同名称，生成待付款节点，手工录入付款金额等重要信息，上传付款申请发起审批流程'
    }, {
      name1: '审批管理',
      name2: '结算审批',
      name3: '选择合同名称，列出剩余付款节点，手工录入各节点付款金额等重要信息，上传付款申请发起审批流程'
    }, {
      name1: '审批管理',
      name2: '支付记录',
      name3: '选择合同名称，显示该合同的支付计划、变更支付计划及支付状态'
    }, {
      name1: '审批管理',
      name2: '投标审批',
      name3: '输入投标类别、项目名称等投标信息，发起审批流程，生成投标申请列表'
    }, {
      name1: '行政管理',
      name2: '出差管理',
      name3: '申请人在线提交出差申请，说明清楚出差事由、目的地、出发/返回时间、费用归属合同等，发起审批流程'
    }, {
      name1: '行政管理',
      name2: '报销管理',
      name3: '在线提交报销申请，列明报销事项、费用归属项目、报销人员、上传报销申请单，出差费用关联出差申请，发起审批流程'
    }, {
      name1: '行政管理',
      name2: '加班管理',
      name3: '在线提交加班申请，列明加班开始时间结束时间等信息，发起审批流程'
    }, {
      name1: '行政管理',
      name2: '请假管理',
      name3: '请假人在线提交请假申请，说明请假人、请假事由、请假类型（年假/病假/婚假/丧亲假/事假等），发起审批流程'
    }, {
      name1: '行政管理',
      name2: '资产管理',
      name3: '记录公司的所有资产信息，可对资产进行派发/归还/维保操作，生成资产使用记录'
    }, {
      name1: '行政管理',
      name2: '外包管理',
      name3: '记录所有外包服务商/外包服务人员的信息'
    }, {
      name1: '行政管理',
      name2: '车辆管理',
      name3: '记录所有车辆的基本信息和可用时间，员工可选择时间、勾选审批责任人后预约用车，生成预约记录'
    }, {
      name1: '行政管理',
      name2: '物品申请',
      name3: '在线提交物品领用申请，说明物品名称、数量、用途、领用时间，发起审批流程'
    }, {
      name1: '生产管理',
      name2: '工单管理',
      name3: '可派发工单、接受工单、抵达现场确认、工单完成确认并上传维护报告'
    }, {
      name1: '生产管理',
      name2: '计划管理',
      name3: '在线编制工作计划，设置各节点启动/完成时间、负责人，发起审批流程，计划执行中时相关节点负责人可编辑节点状态和完成时间'
    }, {
      name1: '生产管理',
      name2: '进度管理',
      name3: '关联计划管理模块，按节点进展展示进度，自动统计并图表展示计划完成率、计划延期率、节点完成率、延迟天数等数据'
    }, {
      name1: '物流管理',
      name2: '物流申请',
      name3: '在线提交发货申请，填写发运基本信息、发运物品清单，发起审批流程'
    }, {
      name1: '权限管理',
      name2: '岗位权限管理',
      name3: '对各岗位进行系统权限管理'
    }, {
      name1: '权限管理',
      name2: '用户组权限设置',
      name3: '对在职员工进行分组管理，并对其进行系统权限管理'
    }, {
      name1: '帮助',
      name2: '操作手册',
      name3: '下载系统各模块的操作手册'
    }, {
      name1: '帮助',
      name2: '意见反馈',
      name3: '填写意见信息、联系方式，提交反馈'
    }]);
    onMounted(() => {
      getInfo();

      document.onkeydown = function (e) {
        e = window.event || e;

        if (route.path == "/login" && (e.code == "Enter" || e.code == "enter") && !registerVisible.value) {
          //验证在登录界面和按得键是回车键enter
          onSubmit(); //登录函数
        }
      };
    });
    return {
      formState,
      formItemLayout,
      onSubmit,
      rules,
      formRef,
      checked,
      handleChecked,
      projectVisible,
      columns,
      toLogin,
      projectData,
      loadingObj,
      registerData,
      registerRef,
      registerVisible,
      rules1,
      handleCancel,
      handleOk,
      registerLoading,
      model1,
      funData,
      funColumns,
      model2
    };
  }

});